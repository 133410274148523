import { createClient } from "@supabase/supabase-js";
import axios from "axios";

const api = axios.create({
  baseURL: "https://vms-api.siamai.workers.dev/api/v1/",
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 3000,
});

const supabase = createClient(
  "https://woeexpeqouhtojnuyueg.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6IndvZWV4cGVxb3VodG9qbnV5dWVnIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDcwNTY0MzcsImV4cCI6MjAyMjYzMjQzN30.nreTdyMMF6nP0oiZkpLN-MP5P0tV4roDMDBeHZ_va2Y"
);

export const getLogo = async (site_uuid) => {
  const { data } = await supabase.from("account_site").select("site_logo").eq("site_uuid", site_uuid);
  return data[0]["site_logo"];
};

export const postProgram = async (data) => {
  try {
    const response = await api.post("enter/program", data);
    return { res: response.data, error: false };
  } catch (error) {
    return { res: error, error: true };
  }
};

export const submitData = async (site_uuid, body) => {
  body["site_uuid"] = site_uuid;
  const { data } = await supabase.from("booking").insert(body).select();
  return await data;
};
