import React, { useEffect } from "react";
import { Button, Flex, Form, Input, Select, Card, QRCode, Modal } from "antd";
import { submitData, postProgram, getLogo } from "./services/api";
const App = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [template, setTemplate] = React.useState([]);
  const [text, setText] = React.useState("");
  const [data, setData] = React.useState({});
  const [form] = Form.useForm();
  const queryParameters = new URLSearchParams(window.location.search);
  const id = queryParameters.get("id");
  const [logo, setLogo] = React.useState("");

  const mapToKeyValue = (input) => {
    return input.map((item) => {
      return {
        [item.destination]: item.objective,
      };
    });
  };

  const Logo = async () => {
    const logo = await getLogo(id);
    setLogo(logo);
  };

  const getProgram = async () => {
    const { res, error } = await postProgram({
      site_uuid: id,
    });
    setTemplate(mapToKeyValue(res["template"]));
  };

  const downloadQRCode = () => {
    const canvas =
      document.querySelector("#myqrcode").querySelector("canvas") || null;
    if (canvas) {
      const url = canvas.toDataURL();
      const a = document.createElement("a");
      a.download = "QRCode.png";
      a.href = url;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  useEffect(() => {
    Logo();
    getProgram();
  }, []);

  return (
    <div
      style={{
        padding: 24,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#1111",
      }}
    >
      <Modal
        title="กรุณาบันทึกภาพ QR Code ด้านล่างนี้"
        open={isModalOpen}
        okText="ดาวน์โหลด"
        onOk={downloadQRCode}
        onCancel={() => setIsModalOpen(false)}
      >
        <div
          id="myqrcode"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <QRCode bgColor="#fff" size={300} value={text} />
        </div>
      </Modal>
      <Card
        title="ลงทะเบียนล่วงหน้า"
        style={{ width: "90%", height: "100%" }}
        actions={[
          <Button
            type="link"
            onClick={() => {
              window.location.href = `https://www.google.com/search?q=${id}`;
            }}
          >
            นโยบายความเป็นส่วนตัว
          </Button>,
        ]}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {logo && <img src={logo} alt="logo" style={{ width: 200 }} />}
        </div>
        <Form
          form={form}
          scrollToFirstError
          style={{ paddingBlock: 32 }}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 14 }}
          onFinish={(values) => {
            let currentDate = new Date();
            currentDate.setDate(currentDate.getDate() + 7);
            let sevenDaysLaterTimestamp = currentDate.getTime();
            submitData(id, {
              site_uuid: id,
              cardType: "",
              destination: values.destination,
              objective: values.objective,
              licensePlate: values.licensePlate,
              phone: values.phone,
              company: values.company,
              expire: sevenDaysLaterTimestamp,
              img: "",
              metadata: {
                name: values.name,
                id: values.id,
              },
            }).then((data) => {
              form.resetFields();
              setText(data[0].booking_uuid);
              setIsModalOpen(true);
            });
          }}
        >
          <Form.Item name="name" label="ชื่อจริง" rules={[{ required: true }]}>
            <Input />
          </Form.Item>

          <Form.Item
            name="id"
            label="เลขบัตรประชาชน"
            rules={[{ required: true }]}
          >
            <Input type="number" />
          </Form.Item>

          <Form.Item name="company" label="บริษัท" rules={[{ required: true }]}>
            <Input />
          </Form.Item>

          <Form.Item
            name="phone"
            type="tel"
            label="เบอร์โทรศัพท์"
            rules={[{ required: true }]}
          >
            <Input type="tel" />
          </Form.Item>

          <Form.Item name="licensePlate" label="ป้ายทะเบียนรถ">
            <Input />
          </Form.Item>

          <Form.Item
            name="destination"
            label="ติดต่อแผนก"
            rules={[{ required: true }]}
          >
            <Select
              onChange={(value) => {
                setData({ ...data, destination: value });
              }}
              options={template.map((item) => {
                return {
                  label: Object.keys(item)[0],
                  value: Object.keys(item)[0],
                };
              })}
            />
          </Form.Item>

          <Form.Item
            name="objective"
            label="วัตถุประสงค์"
            rules={[{ required: true, message: "กรุณาเลือกวัตถุประสงค์" }]}
          >
            <Select
              options={template
                .filter((item) => Object.keys(item)[0] === data.destination)
                .flatMap((item) => {
                  const key = Object.keys(item)[0];
                  return item[key]
                    .filter((itemValue) => itemValue) // filter out falsy values
                    .map((itemValue) => {
                      // console.log(itemValue); // Logging each itemValue
                      return {
                        label: itemValue,
                        value: itemValue,
                      };
                    });
                })}
            />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 4 }}>
            <Flex gap="small">
              <Button danger onClick={() => form.resetFields()}>
                ล้างข้อมูล
              </Button>
              <Button type="primary" htmlType="submit">
                ส่งข้อมูล
              </Button>
            </Flex>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default App;
